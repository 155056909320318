  <template>
    <v-card color="rgba(255, 255, 255, 0)" class="" elevation="0">
      <v-card-text class="text-center py-0 my-0">
        <h1
          :class="{
            'text-h3': $vuetify.breakpoint.smAndDown,
            'text-h1': $vuetify.breakpoint.h1,
          }"
          class="font-weight-black hidden-xs-only display-3"
          style="color: white; font-size: 5rem;"
          
        >
          {{ title.toUpperCase() }}
        </h1>
        <!-- <p class="text-h3 font-weight-medium">
          {{ subtitle.toUpperCase() }}
        </p> -->
      </v-card-text>
    </v-card>
  </template>
  <script>
  export default {
    name: "DefaultHomeIntro",
    data() {
      return {
          title: "Информационная система учета сырой нефти, газового конденсата, сырого газа и продуктов его переработки (товарного газа)",
        subtitle:
          "",
      };
    },
  };
  </script>
  <style lang="scss" scoped>
  .font {
    font-family: Verdana;
  }

  </style>